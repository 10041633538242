import React from 'react';
import {Link } from 'gatsby';
const BottomCTA = class extends React.Component {
    render() {
        return (
        <div className="bottomCTA">
          <Link className="button" to="/custom-car-build-contact">
              <div>Contact Gizmos About Your Project</div>
          </Link>
        </div>
          )
        }
    }
    export default BottomCTA