// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-templates-service-js": () => import("./../src/templates/service.js" /* webpackChunkName: "component---src-templates-service-js" */),
  "component---src-templates-testimonial-js": () => import("./../src/templates/testimonial.js" /* webpackChunkName: "component---src-templates-testimonial-js" */),
  "component---src-templates-project-js": () => import("./../src/templates/project.js" /* webpackChunkName: "component---src-templates-project-js" */),
  "component---src-pages-404-js": () => import("./../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-custom-car-build-contact-index-js": () => import("./../src/pages/custom-car-build-contact/index.js" /* webpackChunkName: "component---src-pages-custom-car-build-contact-index-js" */),
  "component---src-pages-index-js": () => import("./../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-our-facility-js": () => import("./../src/pages/our-facility.js" /* webpackChunkName: "component---src-pages-our-facility-js" */),
  "component---src-pages-projects-index-js": () => import("./../src/pages/projects/index.js" /* webpackChunkName: "component---src-pages-projects-index-js" */),
  "component---src-pages-services-index-js": () => import("./../src/pages/services/index.js" /* webpackChunkName: "component---src-pages-services-index-js" */),
  "component---src-pages-testimonials-index-js": () => import("./../src/pages/testimonials/index.js" /* webpackChunkName: "component---src-pages-testimonials-index-js" */)
}

